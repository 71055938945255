import { useEffect } from 'react';

function PublicLayout({ children }) {
  useEffect(() => {
    let userId = localStorage.getItem('worlde_user_id');
    console.log(userId);
    if (!userId) {
      userId = window.crypto.randomUUID();
      localStorage.setItem('worlde_user_id', userId);
    }
  }, []);
  return <div className="w-full h-full">{children}</div>;
}

export default PublicLayout;

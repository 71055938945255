import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PublicLayout from '../layouts/PublicLayout';
import { checkGameStatus } from '../api/home';
import { useQuery } from '@tanstack/react-query';

const Home = () => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ['check-status'],
    queryFn: () => checkGameStatus(localStorage.getItem('worlde_user_id')),
  });

  useEffect(() => {
    if (data) {
      if (!data.status) {
        navigate('/result');
      }
    }
  }, [data, navigate]);

  return (
    <PublicLayout>
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex-col gap-4">
        <div className="flex justify-center items-center">
          <img
            className="w-[auto] h-[auto]"
            src="images/home-logo.png"
            alt="Home Logo"
          />
        </div>

        <button
          className="bg-white rounded-[16px] font-sans uppercase px-12 py-4 text-4xl tracking-widest text-brown font-medium hover:bg-brown hover:text-white active:bg-dark-brown active:scale-95 transition-all duration-200"
          onClick={() => navigate('/game')}
        >
          play
        </button>
      </div>
    </PublicLayout>
  );
};

export default Home;
